<script>
</script>

<header>
  <h1><img src="/eb-main-colour.svg" alt="eBucks Logo"></h1>
</header>

<style>
	header {
		display: flex;
		flex-direction: column;
		align-items: center;
		/* flex-shrink: 0; */
		color: #000;
		background-color: rgba(230, 230, 230, 0.7);
	}

	h1 {
		font-size: 1.2rem;
		font-weight: 600;
		margin-block-start: 0.67rem;
		margin-block-end: 0.67rem;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
	}

	img {
		width: 80px;
	}
</style>