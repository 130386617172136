<script>
	import Header from './components/Header.svelte';
	import Footer from './components/Footer.svelte';
  import Tabs from './components/Tabs.svelte';
	import FortyDealsTable from './components/FortyDealsTable.svelte';
	import HeroDealsTable from './components/HeroDealsTable.svelte';

	import { products as allProducts, timestamp as fortyTimestamp} from '../fortyDeals.json';
	import { products as heroProducts, timestamp as heroTimestamp} from '../heroDeals.json';
	import FortyDealsStore from './stores/fortyDealsStore.js';
	import HeroDealsStore from './stores/heroDealsStore.js';

	// NOTE: date/time
	const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', seconds: 'numeric', hour12: false, timeZone: 'Africa/Johannesburg', timeZoneName: 'short' };
	let fortyLastRetrieved = `[ last updated ${new Date(fortyTimestamp).toLocaleString('en-ZA',options)} ]`;
	let heroLastRetrieved = `[ last updated ${new Date(heroTimestamp).toLocaleString('en-ZA',options)} ]`;

	// NOTE: tabs
	let tabs = ['40% Off Deals','Hero Deals'];
	let activeTab = localStorage.getItem('activeTab') || tabs[0];

	const tabChange = (e) => {
		activeTab = e.detail;
		localStorage.setItem('activeTab',e.detail);
	}

	// const fortyProducts = allProducts.filter(product => product.productDiscount.startsWith('SAVE: R60'));
	const fortyProducts = allProducts.filter(product => product.productDiscount === 'GET UP TO 40% OFF');
	for (let i = 0; i < fortyProducts.length; i++) {
		fortyProducts[i].productPrice = fortyProducts[i].productPrice.split('\n');
		fortyProducts[i].randPrice = parseFloat(fortyProducts[i].productPrice[0].replaceAll(/[^\.0-9]/g,'')).toFixed(0);
		fortyProducts[i].discountPrice = (fortyProducts[i].randPrice - fortyProducts[i].randPrice * 0.4).toFixed(0);
		fortyProducts[i].savings = (fortyProducts[i].randPrice * 0.4).toFixed(0);
		fortyProducts[i].eBucksPrice = fortyProducts[i].productPrice[1].replaceAll(' ','');
		fortyProducts[i].id = i;
		delete fortyProducts[i].eBucksPrice;
		delete fortyProducts[i].productPrice;
	}
	FortyDealsStore.set(fortyProducts);

	for (let i = 0; i < heroProducts.length; i++) {
		heroProducts[i].productPrice = heroProducts[i].productPrice.split('\n');
		heroProducts[i].randPrice = Math.round(heroProducts[i].productPrice[0].replaceAll(/[^\.0-9]/g,''));
		if (heroProducts[i].productDiscount === '') heroProducts[i].productDiscount = '0';
		heroProducts[i].productDiscount = Math.round(heroProducts[i].productDiscount.replaceAll(/[^\.0-9]/g,''));
		heroProducts[i].discountPercent = Math.round(heroProducts[i].productDiscount / (heroProducts[i].randPrice + heroProducts[i].productDiscount) * 100);
		heroProducts[i].eBucksPrice = heroProducts[i].productPrice[1].replaceAll(' ','');
		heroProducts[i].id = i;
		delete heroProducts[i].eBucksPrice;
		delete heroProducts[i].productPrice;
	}
	HeroDealsStore.set(heroProducts);
</script>

<Header />
<Tabs {tabs} {activeTab} on:tabChange={ tabChange }/>
<main>
	{#if activeTab === tabs[0]}
		<div class="lastUpdated">{fortyLastRetrieved}</div>		
		{#if $FortyDealsStore.length > 0}
			<FortyDealsTable />
		{:else}
			<div class="emptyList">Nothing in the list...</div>
		{/if}
	{:else if activeTab === tabs[1]}
		<div class="lastUpdated">{heroLastRetrieved}</div>
		{#if $HeroDealsStore.length > 0}
			<HeroDealsTable />
		{:else}
			<div class="emptyList">Nothing in the list...</div>
		{/if}
	{/if}
</main>
<Footer />

<style>
	main {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		align-items: center;
		justify-content: flex-start;
		background-color: #f3f3f3;
		box-shadow: inset 0px 12px 15px -10px rgba(0, 0, 0, 0.3), inset 0px -12px 15px -10px rgba(0, 0, 0, 0.3);
	}

	.lastUpdated {
		margin: 10px 0;
		text-align: center;
		font-weight: 500;
	}

	.emptyList {
		padding: 15px 5px;
		text-align: center;
		font-weight: 600;
		width: 92%;
		vertical-align: middle;
    background-color: rgba(160,160,160,0.4);
    border: none;
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>