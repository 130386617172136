<div class="footer">
  <h3>
    Copyright © 2022
    <a href="https://github.com/clone137">
      &nbsp;<span class="fab fa-github"></span> / clone137</a
    >
  </h3>
</div>

<style>
	.footer {
		justify-content: center;
		text-align: center;
		flex-shrink: 0;
		color: #000;
		background-color: rgba(230, 230, 230, 0.7);
	}

	h3 {
		font-size: 0.8rem;
		font-weight: 600;
		margin-block-start: 0.67rem;
		margin-block-end: 0.67rem;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
	}

	a {
		text-decoration: none;
		color: #039be5;
		-webkit-tap-highlight-color: transparent;
		background-color: transparent;
	}
</style>