<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let tabs;
  export let activeTab;
</script>

<div class="tabs">
  <ul>
    {#each tabs as tab}
    <li on:click={ () => dispatch('tabChange', tab) }><div class:active={tab === activeTab}>{tab}</div></li>
    {/each}
  </ul>
</div>

<style>
  .tabs {
    margin-bottom: 0;
  }

  .active {
    color: black;
    /* color: #039be5; */
    border-bottom: 4px solid #039be5;
    /* border-bottom: 5px solid #039be5; */
    padding-bottom: 5px;
  }

  ul {
    display: flex;
    justify-content: center;
    color: black;
    /* color: #039be5; */
		background-color: rgba(230, 230, 230, 0.7);
    padding: 0;
    margin: 0;
    font-weight: bold;
    list-style-type: none;
  }

  li {
    margin: 0 16px;
    /* font-size: 18px; */
    cursor: pointer;
  }
</style>