<script>
	import { get } from 'svelte/store';
	import HeroDealsStore from '../stores/heroDealsStore.js';

	let heroDealsArray = get(HeroDealsStore);

	let sortBy = {col: "productName", ascending: true};
	
	$: sort = (column) => {
		
		if (sortBy.col == column) {
			sortBy.ascending = !sortBy.ascending
		} else {
			sortBy.col = column
			sortBy.ascending = true
		}
		
		// Modifier to sorting function for ascending or descending
		let sortModifier = (sortBy.ascending) ? 1 : -1;
		
		let sort;
		if (column === 'id' || column === 'randPrice' || column === 'productDiscount' || column === 'discountPercent') {
			sort = (a, b) => (a[column] - b[column]) * sortModifier;
		} else {
			sort = (a, b) => (a[column] < b[column]) ? -1 * sortModifier : (a[column] > b[column]) ? 1 * sortModifier : 0;
		}
		
		heroDealsArray = heroDealsArray.sort(sort);
	}
</script>

		<table>
			<tr>
				<!-- <th on:click={sort("id")}>ID</th> -->
				<th>Image</th>
				<th on:click={sort("productName")}>Name</th>
				<th on:click={sort("randPrice")} style="text-align:right">Price</th>
				<th on:click={sort("productDiscount")} style="text-align:right">Discount</th>
				<th on:click={sort("discountPercent")} style="text-align:right">%</th>
			</tr>

			{#each heroDealsArray as heroDeal}
			<tr>
				<!-- <td>{heroDeal.id}</td> -->
				<td><img src="https://www.ebucks.com{heroDeal.productImage}" alt="{heroDeal.productName}"/></td>
				<td><a href="https://www.ebucks.com{heroDeal.productUrl}">{heroDeal.productName}</a></td>
				<td style="text-align:right">R{heroDeal.randPrice}</td>
				<td style="text-align:right">R{heroDeal.productDiscount}</td>
				<td style="text-align:right">{heroDeal.discountPercent}%</td>
			</tr>
			{/each}
		</table>

<style>
  table {
		width: 92%;
		display: table;
		border-collapse: collapse;
		border-spacing: 0;
		text-indent: initial;
		border: none;
	}

	tr {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	tr:hover {
		background-color: rgba(230, 230, 230, 0.6);
	}

	th {
		padding: 15px 5px;
		display: table-cell;
		text-align: left;
		vertical-align: middle;
    background-color: rgba(160,160,160,0.4);
		border: none;
		cursor: pointer;
	}

	td {
		padding: 15px 5px;
		display: table-cell;
		text-align: left;
		vertical-align: middle;
		border: none;
	}

	img {
		width: 48px;
	}
</style>